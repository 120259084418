
import { Link } from 'gatsby';
import React from 'react';

//import * as headerStyles from '../css/common.modules.css';
//import Img from "../../images/buble.png";
const FtImg = 'https://cdn.getmybubble.com/images/new-quote-flow/bubble-logo-white.svg';
// import Footer from "../../components/footer";
import * as FooterStyles from '../styles/footer.module.css';

const Footer = () => {
  var year = new Date().getFullYear();
  return (
    <div className={FooterStyles.footerBox}>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className={FooterStyles.footerLogo}>
              <Link to="/" className={FooterStyles.footerLogoLink}>
                <img src={FtImg} loading="lazy" className={FooterStyles.FtImg} alt="logo"/></Link>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 col-lg-12">
            <div className={FooterStyles.footerText + ' tonerFooterText'}>
              <p>The Toner Group offers insurance policies through our partner at Bubble Insurance Solutions </p>

              <p>Bubble aims to make it easy, convenient, quick and affordable for homeowners to protect their homes, loved ones and everything under their roof with smart insurance products for total assurance. Learn more about Bubble Insurance</p>

              <p className={FooterStyles.ftParaGraph}>©Bubble Insurance Solutions,&nbsp;{year} | All rights reserved</p>

              {/* <p className={FooterStyles.ftParaGraph}>Made with&nbsp;&nbsp;❤️&nbsp;&nbsp;in California.</p> */}
            </div>
          </div>

          {/* <div className="col-md-12 col-lg-6 d-sm-block tabmar-t-30">
            <div className="row">
              <div className="col-12 col-md-3 col-lg-3 section30 section31">
                <div className={FooterStyles.footerLinkList}>
                  <h4 data-bs-toggle="collapse" data-bs-target="#collapseOne1" aria-expanded="true" aria-controls="collapseOne1">product <img class="select-box-icon" src="https://cdn.onlinewebfonts.com/svg/img_295694.svg" alt="Arrow Icon" aria-hidden="true"></img></h4>
                  <ul id="collapseOne1" class=" collapse show" aria-labelledby="headingOne1" data-parent="#accordion" >
                    <li>
                      <Link to="/plan" className={FooterStyles.footList}>plans</Link>
                    </li>
                    <li>
                      <Link to="/homeowners-insurance" className={FooterStyles.footList}>Home Insurance</Link>
                    </li>
                    <li>
                      <Link to="/life-insurance" className={FooterStyles.footList}>Life Insurance</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-6 section31">
                <div className={FooterStyles.footerLinkList}>
                  <h4 data-bs-toggle="collapse" data-bs-target="#collapseOne2" aria-expanded="true" aria-controls="collapseOne2">Company <img class="select-box-icon" src="https://cdn.onlinewebfonts.com/svg/img_295694.svg" alt="Arrow Icon" aria-hidden="true"></img></h4>
                  <div id="collapseOne2" class="row collapse show" aria-labelledby="headingOne2" data-parent="#accordion"  >
                    <div className="col-6 col-md-6 ">
                      <ul>
                        <li>
                          <Link to="/blog" className={FooterStyles.footList}>blog</Link>
                        </li>
                        <li>
                          <Link to="/faq" className={FooterStyles.footList}>FAQs</Link>
                        </li>
                        <li>
                          <Link to="/testimonials" className={FooterStyles.footList}>Testimonials</Link>
                        </li>
                        <li>
                          <Link to="/license-numbers" className={FooterStyles.footList}>License Numbers
                          </Link>
                        </li>
                      </ul>


                    </div>
                    <div className="col-6 col-md-6 col-lg-6">
                      <ul>
                        <li>
                          <Link to="/terms-of-service" className={FooterStyles.footList}>TnC</Link>
                        </li>
                        <li>
                          <Link to="/privacy-policy" className={FooterStyles.footList}>Privacy Policy</Link>
                        </li>
                        <li>
                          <Link to="/accessibility" className={FooterStyles.footList}>Accessibility</Link>
                        </li>
                      </ul>
                    </div>
                  </div>

                </div>
              </div>
              <div className="col-12 col-md-3 section31">
                <div className={FooterStyles.lastDiv}>
                  <div className={FooterStyles.footerLinkList}>
                    <h4 data-bs-toggle="collapse" data-bs-target="#collapseOne3" aria-expanded="true" aria-controls="collapseOne3">follow us <img class="select-box-icon" src="https://cdn.onlinewebfonts.com/svg/img_295694.svg" alt="Arrow Icon" aria-hidden="true"></img></h4>
                    <ul id="collapseOne3" class="row collapse show" aria-labelledby="headingOne3" data-parent="#accordion" >
                      <li>
                        <a href="https://www.facebook.com/GetMyBubble" className={FooterStyles.footList} target="_blank" rel="noreferrer noopener">facebook</a>
                      </li>
                      <li>
                        <a href="https://twitter.com/getmybubble" className={FooterStyles.footList} target="_blank" rel="noreferrer noopener">twitter</a>
                      </li>
                      <li>
                        <a href="https://www.linkedin.com/company/getmybubble" className={FooterStyles.footList} target="_blank" rel="noreferrer noopener">LinkedIn</a>
                      </li>
                      <li>
                        <a href="mailto:hello@getmybubble.com" className={FooterStyles.footList} target="_blank" rel="noreferrer noopener">contact us</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          <div className="col-12 col-md-12 fter-lst-txt">
           Bubble Insurance Solutions (“Bubble”) is a general agent for insurance companies, such as Stillwater Insurance Group, Pacific Life Insurance Company, and The Savings Bank Mutual Life Insurance Company of Massachusetts [SBLI]. Bubble is licensed as a property casualty and life insurance agency in CA, AZ, NJ, VA, NC, OH, and TX. Availability and qualification for coverage, terms, rates, and discounts may vary by jurisdiction. Bubble receives compensation based on the premiums for the insurance policies it sells. Please refer to Terms and Conditions for additional information
          </div>
        </div>
      </div>
    </div >

  );
};
export default Footer;


